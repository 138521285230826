import { Controller } from "@hotwired/stimulus";
import { i18n } from "../components/locales_handler"
import { createElement, updateAttributes } from "../components/utils";

export default class extends Controller {
  static targets = [
    "nestedFormFields", "bulkActionsMenu", "bulkActionsButton",  "bulkActionFieldWrapper", "bulkActionTitle",
    "bulkActionsModal", "bulkActionsField","bulkActionsSave", 'productVariantImageUploadModal',
    'saveVariantImage'
  ]

  connect() {
    this.selectedVariantItems = [];
    this.variantsList = [];
    this.avatarUrl = "";
    this.avatarId = "";

    this.productVariantImageUploadController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-product-image-upload-target="attachmentsFilesWrapper"]'),
      "product-variant-upload"
    );
    this.bulkActionController = this.application.getControllerForElementAndIdentifier(
      this.nestedFormsFieldsTarget,
      "bulk-actions"
    )

    document.addEventListener("initializeVariantsList", this.handleInitializeVariantsList.bind(this));
    document.addEventListener("updateAvatarUrlForBulkActions", this.updateAvatarUrlForBulkActions.bind(this));
    document.addEventListener("updateSelectedVariantItems", this.updateSelectedVariantItems.bind(this));

    this.addBulkActionsScrollListener();
  }

  updateSelectedVariantItems (event) {
    this.selectedVariantItems = event.detail.selectedVariantItems
  }

  closeMenuOutsideClick = (event) => {
    if (!event.target.closest('.bulk-actions-menu')) {
      this.bulkActionsMenuTarget.classList.add('hidden');
      document.removeEventListener('click', this.closeMenuOutsideClick);
    }
  }

  toggleBulkActionsMenu() {
    this.bulkActionsMenuTarget.classList.toggle('hidden');
    if (!this.bulkActionsMenuTarget.classList.contains('hidden')) {
      document.addEventListener('click', this.closeMenuOutsideClick);
    } else {
      document.removeEventListener('click', this.closeMenuOutsideClick);
    }
  }

  handleInitializeVariantsList(event) {
    this.variantsList = event.detail.variantsList;
  }

  addBulkActionsScrollListener() {
    function throttle(callback, delay) {
      let isThrottled = false;
      return function () {
        if (!isThrottled) {
          callback.apply(this, arguments);
          isThrottled = true;
          setTimeout(() => {
            isThrottled = false;
          }, delay);
        }
      };
    }
    window.addEventListener("scroll", throttle(this.bulkActionsScrollHandler.bind(this), 100));
  }

  bulkActionsScrollHandler() {
    const bulkActionsElement = $('.bulk-actions-menu').parent();
    const bulkActionsMenuDropdown = $(bulkActionsElement).find('.bulk-actions-menu-dropdown');
    let classesAdded = false;

    window.addEventListener("scroll", () => {
      const rect = bulkActionsElement[0].getBoundingClientRect();
      const shouldAddClasses = rect.bottom <= 0 || rect.top >= window.innerHeight;

      if (shouldAddClasses !== classesAdded) {
        $(bulkActionsElement).toggleClass('left-[40%] fixed bottom-0 z-[10000]', shouldAddClasses);
        $(bulkActionsMenuDropdown).toggleClass('mt-2 bottom-[75%]', shouldAddClasses);

        classesAdded = shouldAddClasses;
      }
    });
  }

  //TODO: Bulk Actions

  performBulkAction(event) {
    this.variantListSectionController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller="variant-list-section"]'),
      "variant-list-section"
    );
    const action = event.currentTarget.dataset.paramAction;

    switch (action) {
      case 'delete':
        this.handleDeleteAction();
        break;
      case 'avatar_url':
        this.handleAvatarUrlAction();
        break;
      default:
        this.handleDefaultAction(action);
        break;
    }

    this.toggleBulkActionsButton();
  }

  handleDeleteAction() {
    const nestedFormsFields = this.nestedFormFieldsTargets.filter(nestedForm => nestedForm.hasAttribute('data-list-id'));
    this.selectedVariantItems.forEach(variant => {
      const currentVariantFieldItem = nestedFormsFields.find(nestedForm => parseInt(nestedForm.dataset.listId) === variant.listId);

      if (currentVariantFieldItem) {
        variant.newArchived = true;
        const dataListId = currentVariantFieldItem.dataset.listId;
        this.variantListSectionController.checkIsValueAssignedSomewhere(variant, currentVariantFieldItem, dataListId);
      }
    });
    this.variantListSectionController.handleVariantListChanges();
    this.selectedVariantItems = [];
  }

  handleAvatarUrlAction() {
    const toggleVariantImageModalBtn = document.querySelector('.toggle-image-variant-modal');
    this.productVariantImageUploadModalTarget.setAttribute('data-param-action', 'bulk');
    toggleVariantImageModalBtn.click();

    const clonedVariantImgModalDoneBtn = this.saveVariantImageTarget.cloneNode(true);
    this.saveVariantImageTarget.classList.add('hidden');
    clonedVariantImgModalDoneBtn.classList.remove('save-variant-image');

    updateAttributes(clonedVariantImgModalDoneBtn, [
      'data-action',
      'data-product-image-upload-url-param',
      'data-bulk-actions-target',
      'data-product-image-upload-target'
    ], 'remove');

    updateAttributes(clonedVariantImgModalDoneBtn, {
      'data-bulk-actions-target': 'bulkActionsSave',
      'data-param-action': 'avatar_url',
      'data-action': 'click->bulk-actions#saveBulkVariants click->modal#close'
    }, 'set');

    this.saveVariantImageTarget.insertAdjacentElement("afterend", clonedVariantImgModalDoneBtn);
  }

  handleDefaultAction(action) {
    this.bulkActionsSaveTarget.setAttribute('data-param-action', action);
    this.bulkActionsSaveTarget.disabled = true;
    this.generateBulkActionTitle(action);
    this.bulkActionsSaveTarget.classList.remove('btn-primary');
    this.bulkActionsSaveTarget.classList.add('bg-slate-100', 'cursor-not-allowed');
    this.bulkActionsModalTarget.click();
  }

  toggleBulkActionsButton() {
    this.toggleBulkActionsMenu();
    const bulkActionsMenuParent = $('.bulk-actions-menu').parent();
    bulkActionsMenuParent.toggleClass('hidden', this.selectedVariantItems.length === 0);
  }

  updateAvatarUrlForBulkActions(event){
    const { avatarUrl, avatarId } = event.detail
    this.avatarUrl = avatarUrl
    this.avatarId = avatarId
    this.selectedVariantItems.forEach(variant => {
     variant.avatar_url = this.avatarUrl;
     variant.avatar_id = avatarId
    });
  }

  generateBulkActionTitle(action) {
    const actionConfig = {
      price: {
        name: action,
        title: i18n.t('variants.bulk.edit_price'),
        dataAction: 'input->bulk-actions#updateAllSelectedVariants change->variant-list-section#formatPrice',
        inputType: 'number',
      },
      delete: {
        title: i18n.t('variants.bulk.delete_variant'),
      },
      available: {
        name: action,
        title: i18n.t('variants.bulk.edit_availability'),
        dataAction: 'input->bulk-actions#updateAllSelectedVariants change->variant-list-section#formatPrice',
        inputType: 'number',
      }
    };

    const config = actionConfig[action] || { title: i18n.t('variants.unknown_action') };

    if (config.dataAction) {
      this.generateInputField(action, config.dataAction, config.inputType);
      this.bulkActionTitleTarget.textContent = config.title;
    }
  }

  generateInputField(actionType, dataAction, inputType) {
    const bulkActionContainer = this.bulkActionFieldWrapperTarget;
    if (!bulkActionContainer) {
      console.warn('Container for bulk actions not found.');
      return;
    }

    const inputContainerAttributes = {
      'data-bulk-actions-target': 'bulkActionsField',
    };
    const inputContainerWrapper = createElement('div', {
      attributes: inputContainerAttributes,
    })
    const inputContainer = createElement('div', {
      classes: ['my-2', 'mx-5']
    });

    const inputField = createElement('input', {
      attributes: {
        type: inputType,
        name: actionType,
        id: actionType,
        'data-action': dataAction,
      },
      classes: ['rounded-md', 'w-full']
    });

    const label = createElement('label', {
      classes: ['text-slate-600', 'mb-2']
    })
    label.textContent = `Apply a ${actionType} to all selected variants`

    inputContainer.appendChild(label);
    inputContainer.appendChild(inputField);
    inputContainerWrapper.appendChild(inputContainer);
    bulkActionContainer.appendChild(inputContainer);
  }

  monitorBulkActionChanges() {
    const initialVariantsList = JSON.stringify(this.variantsList);

    const checkForChanges = () => {
      const currentVariantsList = JSON.stringify(this.selectedVariantItems);
      if (initialVariantsList !== currentVariantsList) {
        this.bulkActionsSaveTarget.disabled = false;
        this.bulkActionsSaveTarget.classList.add('btn-primary', 'cursor-pointer')
      } else {
        this.bulkActionsSaveTarget.classList.remove('btn-primary', 'cursor-pointer')
        this.bulkActionsSaveTarget.classList.add('btn-primary-light', 'cursor-not-allowed')
        this.bulkActionsSaveTarget.disabled = true;
      }
    };
    checkForChanges();
  }

  updateEachVariant(event) {
    const BULK_ACTION_TYPE = event.target.name;
    const newValue = event.target.value;
    const variantId = event.currentTarget.closest('[data-bulk-actions-target="bulkActionsField"]').dataset.variantId;
    const variantToUpdate = this.selectedVariantItems.find(variant => variant.id === parseInt(variantId));

    if (!variantToUpdate) {
      console.error(`Variant with ID ${variantId} not found in selectedVariantItems`);
      return;
    }

    if (!variantToUpdate.hasOwnProperty(BULK_ACTION_TYPE)) {
      console.error(`Property ${BULK_ACTION_TYPE} does not exist on variant`, variantToUpdate);
      return;
    }

    variantToUpdate[BULK_ACTION_TYPE] = newValue;
    this.monitorBulkActionChanges();
  }

  deleteVariantsInBulk (event) {
    this.updateAllSelectedVariants(event)
  }

  updateAllSelectedVariants (event) {
    const BULK_ACTION_TYPE = event.target.name;
    this.selectedVariantItems.forEach(variant => {
      if (variant.hasOwnProperty(BULK_ACTION_TYPE)) {
        variant[BULK_ACTION_TYPE] = event.target.value;
      } else {
        console.error(`Property ${BULK_ACTION_TYPE} does not exist on variant`, variant);
      }
    });
    this.monitorBulkActionChanges();
  }

  closeBulkActionModal() {
    const container = this.bulkActionFieldWrapperTarget;
    if (container) {
      container.innerHTML = '';
    }
  }

  saveBulkVariants(event) {
    this.updateVariantsList();
    this.variantListSectionController.variantListManagement();
    this.dispatchVariantListChangeEvent();
    this.closeBulkActionModal();
    this.handleModalClose(event);
    this.resetImageUploadModal();
  }

  updateVariantsList() {
    this.selectedVariantItems.forEach(selectedVariant => {
      const variantIndex = this.variantsList.findIndex(variant => variant.listId === selectedVariant.listId);
      const variant = this.variantsList[variantIndex]
      const isArchivedVariant = variant.newArchived;

      if (variantIndex !== -1 && !isArchivedVariant) {
        this.variantsList[variantIndex] = { ...this.variantsList[variantIndex], ...selectedVariant };
      }
    });
  }

  dispatchVariantListChangeEvent() {
    window.dispatchEvent(new CustomEvent("detectChangesInVariantList", { detail: { variantsList: this.variantsList } }));
  }

  handleModalClose(event) {
    if (event.target.dataset.paramAction === 'avatar_url') {
      $('.close-variant-image-upload-modal').trigger('click');
    } else {
      event.target.previousElementSibling.click();
    }
  }

  resetImageUploadModal() {
    this.productVariantImageUploadModalTarget.removeAttribute('data-param-action');
    const avatarSaveTarget = this.bulkActionsSaveTargets.find(target => target.dataset.paramAction === "avatar_url");
    if (avatarSaveTarget) {
      avatarSaveTarget.remove();
    }
    this.saveVariantImageTarget.classList.remove('hidden');
  }
}
