import { Controller } from "@hotwired/stimulus"
import $ from "jquery"
import OrderItemsController from "./order_items_controller";

export default class extends Controller {
  static targets = ["productImageInput", "selectVariantImage", "productVariantImageUploadModal", "nestedForm"];
  static openVariantImagesModelFlag = false;

  connect() {
    this.variantAvatar = "";
  }

  uploadProductAttachments(event) {
    const request_url = event.params.url;
    const input = this.productImageInputTarget;

    if (input.files && input.files.length > 0) {
      const formData = new FormData();

      // Calling Loop through each file and append it to the FormData
      for (let i = 0; i < input.files.length; i++) {
        formData.append('attachments[]', input.files[i]);
      }
      $('.file-upload-input').prop( "disabled", true );
      $('.spinner-loader').removeClass("hidden");
      $('.add-image-text').css("display",'none');
      fetch(request_url, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
        }
      })
      .then(response => response.json())
      .then(data => {
        // Check if the response contains a message
        this.clearLoadingContainer();
        let element = document.querySelector(".main-flash-container");
        if (data && data.message) {
          // Handle success message
          let status = true
          element.innerHTML +=
          this.custom_flash_message().prototype.custom_flash_message(
            data.message.toString(),
            element,
            status
          );
        } else if (data && data.error) {
          // Handle error message
          let status = false
          element.innerHTML += this.custom_flash_message().prototype.custom_flash_message(
            data.error.toString(),
            element,
            status
          );
        }
      })
      .catch(error => {
        console.error('Error:', error);
        this.clearLoadingContainer();
      });
    }
  }

  clearLoadingContainer(){
    $('#reload-list').load(window.location.href + ' #reload-list > *');
    $('#attachments').load(window.location.href + ' #attachments > *');
    $('.add-image-text').css("display",'block');
    $('.btn-loader').css('display','none');
  }

  async openVariantImage(event) {
    if(Controller.openVariantImagesModelFlag) return ;

    Controller.openVariantImagesModelFlag = true;
    
    await new Promise(resolve => {
      $('#reload-list').load(window.location.href + ' #reload-list > *', resolve);
    });

    const listId = $(event.target).closest('.nested-variant-forms').data('list-id')
    const variantId = $(event.target).closest('.nested-variant-forms').find('.variant_id').val();
    const variantSku = $(event.target).closest('.nested-variant-forms').find('.variant_sku').val();
    const toggleVariantModal = document.querySelector('.toggle-image-variant-modal');
    toggleVariantModal.click();
    const editableVariantModal = document.querySelector('.save-variant-image');
    $(editableVariantModal).attr('data-list-id', listId)
    if (variantId) {
      $(editableVariantModal).attr('data-variant-id', variantId);
    }
    $(editableVariantModal).attr('data-variant-sku', variantSku)
    $(event.target).attr('data-list-id', listId)

    const variantImages = $('.product-add-image');
    const selectedImage = Array.from(variantImages).find(imageElement => imageElement.currentSrc === event.target.src);
    if (selectedImage) {
      const isChecked = $(selectedImage).next();
      if (isChecked && isChecked.is('input')) {
        isChecked.prop('checked', true);
      } else {
        console.log("No valid next sibling found.");
      }
    } else {
      console.log("No match found.");
    }
    Controller.openVariantImagesModelFlag = false;
  }

  handleSelectedVariantImage(event) {
    this.selectVariantImageTargets.forEach((otherCheckbox) => {
      if (otherCheckbox !== event.currentTarget) {
        otherCheckbox.checked = false;
        otherCheckbox.classList.remove("selected-image");
      }
    });

    if (event.currentTarget.checked) {
      event.currentTarget.classList.add("selected-image");
    } else {
      event.currentTarget.classList.remove("selected-image");
    }

    if(this.productVariantImageUploadModalTarget.hasAttribute('data-param-action')){
      this.selectVariantImageTargets.forEach(imageTarget => {
        imageTarget.setAttribute('name', 'avatar_url');
        imageTarget.setAttribute('data-action', 'click->product-image-upload#handleSelectedVariantImage input->bulk-actions#updateAllSelectedVariantItems');
      });
    } else {
      this.selectVariantImageTargets.forEach(imageTarget => {
        imageTarget.setAttribute('data-action', 'click->product-image-upload#handleSelectedVariantImage');
        imageTarget.removeAttribute('name', 'avatar_url');
      });
    }

    const avatarId =  $('.selected-image').attr('data-avatar-id')
    const closestImgUrl = $('.selected-image').siblings('img').attr('src')
    this.variantAvatar = closestImgUrl;

    document.dispatchEvent(new CustomEvent("updateAvatarUrlForBulkActions", {
      detail: {
        avatarUrl: closestImgUrl || '',
        avatarId: avatarId || ''
      }
    }))
  }

  addSelectedImageToVariant(event){
    const listId = event.target.dataset.listId
    const avatarId = $(".selected-image").attr('data-avatar-id');
    const variantListSectionController = this.application.getControllerForElementAndIdentifier(
                                            document.querySelector('[data-controller="variant-list-section"]'),
                                            'variant-list-section'
                                          );
    const variant = variantListSectionController.variantsList.find(variant => variant.listId == listId);

    if (variant) {
      variant.avatar_url = this.variantAvatar || '';
      variant.avatar_id = avatarId || '';
    }

    variantListSectionController.variantListManagement();
    variantListSectionController.closeModalAndReturnToOriginalPosition();
    $('.close-variant-image-upload-modal').trigger('click');
  }

  custom_flash_message() {
    return OrderItemsController;
  }
}
