import { Controller } from "@hotwired/stimulus"
import { i18n } from "../components/locales_handler"

export default class extends Controller {
  static targets = ["optionKey", "optionValue", "optionSection", "deleteButton", "optionFieldSet",
                    "variantItemSection", "optionContext", "optionValueContext", "itemSection", "itemViewSection",
                    "dragButton"]

  connect() {
    // Initialize variables
    this.variantOptions = {}
    this.currentIdentifier = 1
    this.sectionId = this.fourDigitRandomID();
    // it adds the id attribute on variants-item-section
    this.variantItemSectionTarget.dataset.id = this.variantItemSectionTarget.dataset.id || this.sectionId;
    this.optionValueTarget.dataset.fieldId = this.currentIdentifier

    //this dispatches a native change event when select2:select event is fired
    $(this.optionKeyTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
    // add this event in document to access it from variant_list_section_controller
    document.addEventListener('addSpanElementForOption', this.addSpanElement.bind(this));
    // add this event event in document to access it in variant_list_section_controller
    document.addEventListener('toggleVariantSection', this.toggleVariantSection.bind(this));

    document.dispatchEvent(new CustomEvent('resetVariantSelections'))

    this.addSectionIdToOptionValueFields();
    setTimeout(() => {
      this.updateDeleteButtonVisibility();
      document.dispatchEvent(new CustomEvent("initializeSortableLists"))
    }, 100);
  }

  handlePreviousOptionInputValue(event) {
    // Check if the input is empty and the dataset value is not empty
    if (event.target.value === '' && event.target.dataset.value !== '') {
      return event.target.value = event.target.dataset.value;
    }
  }

  handleOptionInputValue(event) {
    const inputValue = event.target.value.trim();
    if (inputValue == '' && !event.target.dataset.value  == '') return

    event.target.dataset.value = event.target.value
    let selectBox = this.variantItemSectionTarget.querySelector('.select-box')
    this.optionKeyTarget.value = selectBox.options[selectBox.selectedIndex].value

    // Generating object for each variant item sections
    this.variantOptions = {
      sectionId: this.sectionId,
      optionKey: this.optionKeyTarget.value,
      optionValues: []
    }
    this.optionValueTargets.forEach((field, index) => {
      if (field.value) {
        this.variantOptions.optionValues.push(field.value)
      }
    })

    this.addEmptyOptionValueField();
    this.addSpanElement();
    this.updateDeleteButtonVisibility();

    setTimeout(() => {
      document.dispatchEvent(new CustomEvent("initializeSortableLists"))
    }, 100);

    let optionRequired = $(this.itemSectionTarget).find('.option-required')
    if (optionRequired.hasClass('hidden') && this.areInputsEmpty()) {
      optionRequired.removeClass('hidden')
    }
    else {
      optionRequired.addClass('hidden')
    }
    document.dispatchEvent(new CustomEvent('resetVariantSelections'))
  }

  addEmptyOptionValueField() {
    let isBlankFieldDetected = true
    this.optionValueTargets.forEach(field => {
      if (!field.value.trim()) {
        isBlankFieldDetected = false
      }
    })

    if (isBlankFieldDetected) {
      this.cloneEmptyOptionInput(this.optionSectionTarget, this.optionFieldSetTarget)
      this.addSectionIdToOptionValueFields();
    }
  }

  cloneEmptyOptionInput(optionSectionTarget, optionFieldSetTarget) {
    let clone = $(optionFieldSetTarget).clone(true);

    this.currentIdentifier++;
    let variantInput = clone.find(".variant_input");
    variantInput.data('fieldId', this.currentIdentifier);
    variantInput.val('');
    variantInput.attr('data-value','');
    variantInput.attr('placeholder', i18n.t('variants.add_another_value'));
    clone.find(".delete_button").addClass('invisible');
    clone.find(".drag-option-value-handler").addClass('invisible')
    clone.addClass('mt-1');
    $(optionSectionTarget).append(clone);
  }

  // it updates the optionKey attributes on optionValueTarget
  changeOptionKeyHandler(event) {
    this.optionContextTarget.textContent = this.optionKeyTarget.value
    this.optionValueTargets.forEach(variantInput => {
      variantInput.dataset.optionKey = this.optionKeyTarget.value
    })
    this.manageVariantsList();
  }

  // Dispatches the "manageVariantsList" event to trigger variant list management in variant_list_section_controller
  manageVariantsList() {
    const event = new CustomEvent("manageVariantsList", { bubbles: true });
    const variantListControllerElement = document.querySelector('[data-controller="variant-list-section"]');
    variantListControllerElement.dispatchEvent(event);
  }

  addSpanElement() {
    let parentElement = this.optionValueContextTarget;
    parentElement.innerHTML = '';

    for (let i = 0; i < this.optionValueTargets.length; i++) {
      const field = this.optionValueTargets[i];

      if (field.value.trim() !== '') {
        const spanElement = document.createElement('span');
        spanElement.classList.add('rounded-xl', 'bg-gray-50', 'px-3', 'text-slate-500', 'py-0.5', 'overflow-wrap', 'break-all');
        spanElement.textContent = field.value;

        // Append the span to the container
        parentElement.appendChild(spanElement);
      }
    }
  }

  toggleVariantSection (){
    let optionRequired = $(this.itemSectionTarget).find('.option-required')
    if (!this.areInputsEmpty()) {
      this.itemSectionTarget.classList.toggle('expand');
      this.itemViewSectionTarget.classList.toggle('expand');
      if (!optionRequired.hasClass('hidden')) {
        optionRequired.addClass('hidden')
      }
    this.addSpanElement()
    }
    else {
      optionRequired.removeClass('hidden');
    }
  }

  areInputsEmpty() {
    // Get all input elements within the item section
    var inputs = this.itemSectionTarget.querySelectorAll('input');

    // Check if all inputs are empty
    return Array.from(inputs).every(function(input) {
        return input.value.trim() === '';
    });
  }

  updateDeleteButtonVisibility() {
    // Call the generic function for deleteButtonTargets
    this.updateButtonVisibility(this.deleteButtonTargets);
    // Call the generic function for dragButtonTargets
    this.updateDragButtonVisibility(this.dragButtonTargets);
  }

  updateButtonVisibility(buttonTargets) {
    buttonTargets.forEach((button, index) => {
      if (index == (buttonTargets.length - 1)) {
        button.classList.add('invisible');
      } else {
        button.classList.toggle('invisible', buttonTargets.length <= 2);
      }
    });
  }

  updateDragButtonVisibility (buttonTargets) {
    buttonTargets.forEach((button, index) => {
      const optionSection = button.closest('.option-section');
      const lastChild = optionSection.lastElementChild;
      const isLastButton = index == (buttonTargets.length - 1);
      const isEmptyInput = lastChild.querySelector('.variant_input').value === '';
      const children = Array.from(optionSection.children);

      if (buttonTargets.length > 1 && isLastButton) {
        button.classList.add('invisible');
        if (isEmptyInput) {
          this.setOrResetChildAttributes(lastChild, 'set', { className: ['exclude', 'truly-fixed'], draggable: 'false', droppable: 'false' });
        }
      } else {
        button.classList.remove('invisible');
        this.setOrResetChildAttributes(children, 'reset');
        if (buttonTargets.length > 1) {
          this.setOrResetChildAttributes(lastChild, 'set', { className: ['exclude', 'truly-fixed'], draggable: 'false', droppable: 'false' });
        }
      }
    });
  }

  setOrResetAttribute(element, attribute, value = null) {
    if (!value) {
      element.removeAttribute(attribute);
    } else {
      element.setAttribute(attribute, value);
    }
  }

  setOrResetChildAttributes (elements, action, attributes) {
    elements = Array.isArray(elements) ? elements : [elements]

    elements.forEach((element) => {
      if (action === 'set') {
        if (attributes.className) {
          attributes.className.forEach(cls => element.classList.add(cls));
        }
        if (attributes.draggable) {
          this.setOrResetAttribute(element ,'draggable', attributes.draggable);
        }
        if (attributes.droppable) {
          this.setOrResetAttribute(element ,'droppable', attributes.droppable);
        }
      } else if ( action === 'reset' ) {
        element.classList.remove('exclude', 'truly-fixed');
        this.setOrResetAttribute(element, 'draggable')
        this.setOrResetAttribute(element, 'droppable')
      }
    })
  }

  deleteSection(event) {
    const section = event.target.closest(".variant_input_fields_section")
    section.remove()
    this.addSpanElement();
    this.updateDeleteButtonVisibility();

    // TODO: Detect changes when option value is removed
    const variantListControllerElement = document.querySelector('[data-controller="variant-list-section"]');
    const variantItemSectionController = this.application.getControllerForElementAndIdentifier(
      variantListControllerElement,
      'variant-list-section'
    );

    const optionsList = variantItemSectionController.generateOptionsList();
    const eventDetail = { variantOptionsList: optionsList, variantOptionSectionsCount: this.variantItemSectionTargets.length };
    window.dispatchEvent(new CustomEvent("detectVariantOptionChanges", { detail: eventDetail }));
  }

  addSectionIdToOptionValueFields() {
    // Update the data-section-id for all option value fields
    this.optionValueTargets.forEach(field => {
      field.dataset.sectionId = field.dataset.sectionId ? field.dataset.sectionId : this.sectionId
      field.dataset.optionKey = this.optionKeyTarget.value
    })
  }

  fourDigitRandomID() {
    return Math.floor(1000 + Math.random() * 9000)
  }

  moveToNextTab(event) {
    if (event.keyCode !== 13) return;
    event.preventDefault();
    const nextInput = event.target.closest('.option-fieldset').nextElementSibling.querySelector('input');
    if (nextInput) nextInput.focus();
  }

  moveToPreviousField (event) {
    const currentInput  = event.target;
    const previousFieldset = currentInput.closest('.option-fieldset').previousElementSibling;
    const previousInput = previousFieldset ? previousFieldset.querySelector('input') : null;

    if (currentInput.value === "" && event.keyCode === 8) {
      if (previousInput) {
        const fieldDeleteBtn = event.currentTarget.parentElement.querySelector(".delete-field");
        const isLastField = this.optionValueTargets.indexOf(currentInput) === this.optionValueTargets.length - 1;

        if (!isLastField) {
          fieldDeleteBtn.click();
        }
        previousInput.focus();
      } else {
        return
      }
    }
  }
}
