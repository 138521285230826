import { Controller } from "@hotwired/stimulus"
import { checkbox } from "@material-tailwind/react";
import $ from 'jquery';
import { i18n } from "../components/locales_handler"


export default class extends Controller {
  static targets = [
    "optionKey", "optionValue", "variantItemSection", "addOption",
    "productPrice", "variantOptionsLayout", "variantsListSection",
    "variantsListBody", "nameOutput", "nestedForm", "remember", 
    "options", "selectAllVariants"
  ]

  static deleteAllVariantOption = false;

  connect() {
    /**
     * Adds an event listener for the "manageVariantsList" event to this controller.
     * The event will trigger the variantListManagement method, typically called from
     * changeOptionKeyHandler in variant_item_section_controller when the select2 selection changes.
     */
    this.element.addEventListener("manageVariantsList", this.variantListManagement.bind(this));
    this.element.addEventListener('revertVariantsChanges', this.afterConnect.bind(this));
    // add this event in document to access variantListManagement from any other controller
    document.addEventListener("variantListManagement", this.variantListManagement.bind(this));
    document.addEventListener('updateVariantsListState', this.afterConnect.bind(this));
    this.optionsFilterArray = []
    this.selectedFilterOptions = []; //store the selected filter options array
    this.selectedVariantItems = [];
    this.optionsList = []
    this.variantslist = []
    this.missingOptionValues = [];
    this.oldVariantsList = []
    this.optionsListAllSelectors = []
    this.currentOptionListSelector = []

    this.addMoreOptionLinkHandler();
    this.afterConnect();
    document.dispatchEvent(new CustomEvent('resetVariantSelections'))
  }

  afterConnect() {
    const { variantsList, optionsList } = this.getProductVariantsForEditViewAndSetTheVariantList();
    const isOptionSectionOrder = optionsList.every(option => option.hasOwnProperty('order'))

    if (!isOptionSectionOrder) {
      const orderedOptionsList = this.populateOrderInOptionsList(optionsList);
      this.optionsList = orderedOptionsList;
    }

    this.variantsList = variantsList;
    this.optionsList = optionsList;
    // this make sure that variant-item-sections are not collapsed after page reload
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent("toggleVariantSection"));
      document.dispatchEvent(new CustomEvent("initializeSortableLists"))
    }, 120);

    window.dispatchEvent(new CustomEvent("select2:changed",{detail: { variantOptionsList: optionsList, variantOptionSectionsCount: this.variantItemSectionTargets.length, variantsList: this.getNotArchivedVariants(variantsList) }}))
    document.dispatchEvent(new CustomEvent("initializeVariantsList", {
      detail: {
        variantsList: variantsList
      }
    }));
  }

  getNotArchivedVariants(variantsList) {
    return variantsList.filter(variant => !variant.archived)
  }

  updateVariantsListState (event) {
    const { variantsList } = event.detail.variantsList
    this.variantsList =  variantsList
    this.variantListManagement();
  }

  populateOrderInOptionsList(optionsList) {
    optionsList.forEach((option, index) => {
      if (!option.hasOwnProperty('order')) {
        option.order = index + 1;
      }
    });
    return optionsList;
  }

  getProductVariantsForEditViewAndSetTheVariantList() {
    let variantsData = [];
    let optionsList = [];

    if (this.variantsListSectionTarget.dataset.variants !== ' ') {
      try {
        variantsData = JSON.parse(this.variantsListSectionTarget.dataset.variants);
        if (variantsData.length > 0) {
          variantsData = this.mapVariantDataForVariantList(variantsData);
          const allVariantCombinations = variantsData
                                            .filter(variant => !variant.archived)
                                            .flatMap(variant => variant.variantCombinations);
          const notArchivedVariants = this.getNotArchivedVariants(variantsData);
          optionsList = notArchivedVariants[0].options ? JSON.parse(notArchivedVariants[0].options) : [];
          optionsList = optionsList.map(option => {
            return {
              ...option,
              optionValues: option.optionValues.filter(value => allVariantCombinations.includes(value))
            };
          });
          this.optionsListAllSelectors =  optionsList.map(option => option.optionKey).flat()
          if (optionsList) {
            this.optionsListAllSelectors.forEach((option) =>  {
              this.currentOptionListSelector = option
              this.addNewOptionLayoutForEditView();
            })
          }
        }
      } catch (e) {
        console.error('Error parsing JSON:', e);
      }
    }
    setTimeout(() => {
      this.resetVariantItemSectionId(optionsList)
      this.setVariantItemSectionValues(optionsList)
    }, 100);

    return {
      variantsList: variantsData,
      optionsList: optionsList
    };
  }

  mapVariantDataForVariantList(variantsData) {
    variantsData.forEach(variant => {
      variant.listId = this.sixDigitRandomID();
      // Convert the price to a float and limit it to two decimal places
      variant.price = parseFloat(variant.price).toFixed(1);
      variant.variantCombinations = variant.title.split('/');
      delete variant.title;
      variant.newArchived = variant.archived
      variant.avatar_url = variant.avatar_url
    });

    return variantsData
  }

  // display variantItemSection based on optionsList
  addNewOptionLayoutForEditView() {
    const event = new Event("click");
    this.addOptionTarget.dispatchEvent(event);
    $(`.select-box`).select2('close')
  }

  // Reset variantItemSection data-section-id
  resetVariantItemSectionId(optionsList) {
    const variantItemSections = this.variantItemSectionTargets
    variantItemSections.forEach((variantItemSection, index) => {
      variantItemSection.dataset.id = optionsList[index].sectionId;
      variantItemSection.dataset.sectionId = optionsList[index].sectionId;
      variantItemSection.dataset.order = optionsList[index].order;
    });
  }

  getMissingOptionValues(oldOptionsList, newOptionsList) {
    return oldOptionsList.reduce((acc, option) => {
      const correspondingOption = newOptionsList.find(opt => opt.sectionId === option.sectionId);
      if (correspondingOption) {
        const missingValues = option.optionValues.filter(value => !correspondingOption.optionValues.includes(value));
        if (missingValues.length > 0) {
          acc.push({
            sectionId: option.sectionId,
            optionKey: option.optionKey,
            optionValues: missingValues
          });
        }
      }
      this.missingOptionValues = acc;
      return acc;
    }, []);
  }

  deleteMissingOptionValueField (optionsList) {
    const variantItemSectionController = this.application.getControllerForElementAndIdentifier(
      this.variantItemSectionTarget,
      'variant-item-section'
    );
    optionsList.forEach(data => {
      const { sectionId, optionValues } = data;
      let variantItemSection = $(this.variantOptionsLayoutTarget).find(`.variant-item-section[data-id="${sectionId}"]`);

      optionValues.forEach(value => {
        // Find the input element with the provided optionValue
        const inputElement = $(variantItemSection).find(`:input[data-value="${value}"][data-section-id="${sectionId}"]`);

        if (inputElement.length > 0) {
          if(variantItemSectionController.areInputsEmpty()){
            Array.from(document.querySelectorAll(".variant-item-section")).forEach(section => section.remove());
          } else {
            let optionFieldSet = inputElement.closest('.option-fieldset')
            optionFieldSet.remove();
            return;
          }
        }
      });
    });
  }

  setVariantItemSectionValues(optionsList) {
    const variantOptionsLayout  = document.querySelector('.variant-options-layout')
    const missingOptionValues = this.getMissingOptionValues(this.optionsList, optionsList)
    console.log('missingOptionValues', missingOptionValues)
    if(missingOptionValues.length > 0) {
      this.deleteMissingOptionValueField(missingOptionValues);
    }

    optionsList.forEach(data => {
      const  { sectionId, optionValues } = data;
      let variantItemSection = $(variantOptionsLayout).find(`.variant-item-section[data-id="${sectionId}"]`);
      let optionSection = $(variantItemSection).find('.option-section')
      let optionFieldSet = $(variantItemSection).find('.option-fieldset').last()

      optionValues.forEach(value => {
        const existingInput = $(variantItemSection).find(`:input[data-value="${value}"][data-section-id="${sectionId}"]`);

        if (existingInput.length > 0) return;
        const inputElement = $(variantItemSection).find(':input:empty:last');

        if (inputElement.length > 0) {
          // Assuming value is a string, you may need to adapt based on your data
          $(inputElement).val(value);
          $(inputElement).attr('data-value', value);
          $(inputElement).data('sectionId', parseInt(sectionId, 10));
          $(inputElement).attr('data-section-id', parseInt(sectionId, 10));
          // Call the function to add a new empty input field
          this.callCloneEmptyOptionInput(optionSection, optionFieldSet);
        }
      });
    });
      // dispatch this event in variant_item_controller that triggers addSpanElement
    document.dispatchEvent(new CustomEvent("addSpanElementForOption"));
    if(!missingOptionValues.length > 0) {
      this.variantListManagement();
    }
  }

  callCloneEmptyOptionInput(optionSection, optionFieldSet) {
    // Get an instance of the VariantItemSectionController
    const variantItemSectionController = this.application.getControllerForElementAndIdentifier(
      this.variantItemSectionTarget,
      'variant-item-section'
    );

      // Check if the controller instance exists
      if (variantItemSectionController) {
        // Call the method on the other controller instance
        variantItemSectionController.cloneEmptyOptionInput(optionSection, optionFieldSet);
        // variantItemSectionController.updateDeleteButtonVisibility()
      } else {
        console.error('Controller instance not found for variant-item-section');
      }
  }

  variantItemSectionTargetConnected(element) {
    this.addMoreOptionLinkHandler()
  }

  handleOptionInputValue(event) {
    const inputValue = event.target.value
    if (inputValue == '' && !event.target.dataset.value  == '') return
    this.variantListManagement();
    let variantItemSectionId = $(event.target.closest('.variant-item-section')).data('id')
    const variantTitles = document.querySelectorAll('.variant_title');
    variantTitles.forEach((title, index) => {
      if (title.textContent.includes(inputValue)) {
        $(title).closest('.fields').attr('section-id', variantItemSectionId)
      }
    });
  }

  handlePreviousOptionInputValue (event) {
    const inputValue = event.target.value
    const currentVariantItemSectionTarget = event.target.closest('.variant-item-section')
    this.handleDuplicateOptionKey(inputValue, event.target, currentVariantItemSectionTarget)
    this.variantListManagement();
  }

  handleDuplicateOptionKey(inputValue, target, currentVariantItemSectionTarget) {
    // Check for duplicate values excluding the current input
    const isDuplicate = Array.from(currentVariantItemSectionTarget.querySelectorAll(".variant_input"))
    .filter(field => field !== target)  // Exclude the current input
    .some(field => field.value.trim() === inputValue);

    if (isDuplicate) {
      // Slice the input value until it becomes unique
      let slicedValue = inputValue;
      let counter = 1;

      while (Array.from(currentVariantItemSectionTarget.querySelectorAll(".variant_input"))
        .some(field => field.value.trim() === slicedValue)) {
        slicedValue = inputValue.slice(0, -counter.toString().length) + counter;
        counter++;
      }

      // Update the input value and dataset value
      target.value = slicedValue;
      target.dataset.value = slicedValue;
    }
  }

  storeCartesianCombinationsList(variant){
    const getStoreCartesianCombinationList = this.cartesianVariants(variant);
    return getStoreCartesianCombinationList;
  }

  // A main function from where all the logic starts
  variantListManagement() {
    this.generateOptionsList();
    document.dispatchEvent( new CustomEvent("generateVariantFilters") );
    const cartesianCombinations = this.cartesianVariants(this.optionsList);
    const bestMatchList = this.findBestPerfectMatch(cartesianCombinations, this.variantsList);
    this.variantsList = bestMatchList;

    if (this.variantsList.length > 0) {
      const filter_options_list_html = this.element.querySelector(".filter-options");
      filter_options_list_html.classList.remove("hidden");
      this.variantsListSectionTarget.classList.remove("hidden");
      this.variantsListBodyTarget.textContent = "";
      
      /* create filter div and populate data
      All/None do not have any optionValues. so, prevent creating filters for it
      */
      document.dispatchEvent( new CustomEvent("generateFilterOptionsUI") );

      // Adding Variant list in to the nested form
      this.variantsList.forEach((variant) => {
        const { available, barcode, listId, price, sku, variantCombinations, options, id, archived, newArchived, avatar_url, avatar_id } = variant;

        const variantListItems = [
          { fieldName: 'title', value: variantCombinations.join('/'), listId: listId },
          { fieldName: 'combination', value: variantCombinations.join('/'), listId: listId },
          { fieldName: 'price', value: price, listId: listId },
          { fieldName: 'available', value: available, listId: listId },
          { fieldName: 'sku', value: sku, listId: listId },
          { fieldName: 'barcode', value: barcode, listId: listId },
          { fieldName: 'edit_object', value: listId, listId: listId },
          { fieldName: 'delete_object', value: listId, listId: listId },
          { fieldName: 'options', value: options, listId: listId },
          { fieldName: 'id', value: id, listId: listId },
          { fieldName: 'archived', value: archived, listId: listId },
          { fieldName: 'avatar', value: avatar_url, listId: listId },
          { fieldName: 'avatar_id', value: avatar_id, listId: listId },
        ];

        let element = document.querySelector('.editable-variant-modal');
        if (element) {
          element.classList.add('editable-variant-modal-' + listId);
        }

        // Add existing variants to the DOM without triggering new row addition
        const newRow = this.nestedFormTarget.cloneNode(true);

        if (archived) {
          newRow.style.display = 'none';
          newRow.querySelectorAll('input, select, textarea, .variant-add-image-icon, .variant_title').forEach(input => {
            input.readOnly = true;
            input.style.pointerEvents = 'none';

            if (input.classList.contains('variant_title') || input.classList.contains('variant_combination')) {
              input.disabled = true;
            }
          });
        }

        newRow.classList.remove("hidden");
        newRow.dataset.listId = listId;
        newRow.dataset.variantId = id;
        const uniqueId = new Date().valueOf();
        newRow.querySelectorAll('[name^="product[variants_attributes]"]').forEach(field => {
          field.name = field.name.replace(/\[0\]/, `[${uniqueId}]`);
          field.id = field.id.replace(/\[0\]/, `[${uniqueId}]`);
        });
        this.variantsListBodyTarget.appendChild(newRow);

        let nested_forms_list = this.variantsListBodyTarget.querySelectorAll(".nested-form-fields");
        this.assignValuesToVariantListItem(nested_forms_list[nested_forms_list.length - 1], variantListItems);

        document.dispatchEvent( new CustomEvent("assignUniqueClassesToVariants",{
            detail: {
                  nested_form: nested_forms_list[nested_forms_list.length - 1],
                  variant: variant,
                }
        }))
        // this.assignUniqueClassesToVariants(nested_forms_list[nested_forms_list.length - 1], variant, this.optionsFilterArray);
        if (newArchived) {
          this.manageCurrentVariantFieldItem(true, newRow.querySelector('.nested-form-fields'))
        }
      });
    } else {
      const filter_options_list_html = this.element.querySelector(".filter-options");
      this.variantsListSectionTarget.classList.add("hidden");
      this.variantsListBodyTarget.textContent = "";
      filter_options_list_html.classList.add("hidden");
    }

    // dispatch detectVariantOptionChanges() binded in detect_product_changes_controller to reflect latest changes in this.optionsLi
    const eventDetail = { variantOptionsList: this.optionsList, variantOptionSectionsCount: this.variantItemSectionTargets.length };
    window.dispatchEvent(new CustomEvent("detectChangesInVariantList", { detail: { variantsList: this.getNotArchivedVariants(this.variantsList) }}));
    window.dispatchEvent(new CustomEvent("detectVariantOptionChanges", { detail: eventDetail }));
    document.dispatchEvent(new CustomEvent("initializeVariantsList", {
      detail: {
        variantsList: this.variantsList
      }
    }));
  }

  // Generate variantsList from the cartesianCombinations
  findBestPerfectMatch(cartesianCombinations, oldList) {
    if (cartesianCombinations.length < 1) {
      return [];
    }

    this.oldVariantsList = oldList;
    const oldListMap = new Map(oldList.map(item => [item.variantCombinations.join('/'), item]));
    const alreadyMatchedList = new Set();
    let newList = [];

    cartesianCombinations.forEach(combination => {
      const combinationKey = combination.join('/');
      let bestMatch = oldListMap.get(combinationKey) || null;
      let bestScore = bestMatch ? combination.length : 0;

      if (!bestMatch) {
        oldListMap.forEach(oldItem => {
          const score = this.calculateMatchScore(combination, oldItem.variantCombinations);
          if (score > bestScore) {
            bestScore = score;
            bestMatch = oldItem;
          }
        });
      }

      if (bestMatch && !alreadyMatchedList.has(bestMatch)) {
        alreadyMatchedList.add(bestMatch);
        const { available, barcode, listId, price, sku, id, archived, newArchived, avatar_url, avatar_id } = bestMatch;
        const options = this.optionsList ? JSON.stringify(this.optionsList) : null;

        newList.push({ variantCombinations: combination, available, barcode, listId, price, sku, options, id, archived, newArchived, avatar_url, avatar_id });
      } else if (combination.length > 0) {
        newList.push({
          variantCombinations: combination,
          price: $('.product-price input').val() || 0.0,
          available: 0,
          sku: '',
          barcode: '',
          listId: this.sixDigitRandomID(),
          options: JSON.stringify(this.optionsList),
          id: '',
          archived: false,
          newArchived: false,
          avatar_url: '',
          avatar_id: ''
        });
      }
    });

    return newList;
  }

  // Calculate the matching score
  calculateMatchScore(newCombinations, oldCombinations) {
    let score = 0;

    newCombinations.forEach(newValue => {
      oldCombinations.forEach(oldValue => {
        if (newValue.includes(oldValue) || oldValue.includes(newValue)) {
          score++;
        }
      })
    })

    return score;
  }

  // Update the this.variantsList when user changed variant items fields
  // i.e price, available, on holdReady, sku and barcode
  variantLineItemHandler(event) {
    const { fieldName, listId } = event.target.dataset
    const value = event.target.value

    this.variantsList = this.variantsList.map(variant => {
      if (variant.listId == listId) {
        variant[fieldName] = value
      }
      return variant
    })
    // Dispatch a custom event to handle changes in the variants list in detect_product_changer
    window.dispatchEvent(new CustomEvent("detectChangesInVariantList", { detail: { variantsList: this.variantsList } }));
  }

  // This function formats the price input to a float with one decimal place
  formatPrice(event) {
    const input = event.target;
    let value = parseFloat(input.value);

    // Check if the value is a number
    if (!isNaN(value)) {
      // Format the value to a float with one decimal place
      input.value = value.toFixed(1);
    } else {
      input.value = '0.0';
    }
  }

  ensureInteger(event) {
    const input = event.target;
    const value = input.value.replace(/[^0-9-]/g, '');
    input.value = isNaN(parseInt(value)) ? '' : parseInt(value, 10).toString();
  }

  // Generate a Cartesian combinations from this.optionsList
  cartesianVariants(optionsList) {
    if(optionsList.length < 1){
      return []
    }
    return optionsList.reduce((accumulator, currentValue) => {
      if (currentValue.optionValues.length < 1) {
        return accumulator
      }

      return accumulator.flatMap((acc_item) => currentValue.optionValues.map((value) => [...acc_item, value]))
    }, [[]])
  }

  generateOptionsList() {
    let optionsList = [];
    let order = 1;
    // Iterate through variantItemSectionTargets
    this.variantItemSectionTargets.forEach((target) => {
      let optionObject = {
        sectionId: target.dataset.id,
        optionKey: '',
        optionValues: [],
        order: order,
      };

      let relatedOptionValueTargets = target.querySelectorAll(`.variant_input`);
      // Iterate through the found optionValueTargets
      relatedOptionValueTargets.forEach((field) => {
        if (field.value.trim().length !== 0 && field.dataset.sectionId == optionObject.sectionId) {
          optionObject.optionKey = field.dataset.optionKey;
          optionObject.order = field.closest('.variant-item-section').dataset.order
          optionObject.optionValues.push(field.value);
        }
      });

      // Check if optionObject is valid
      if (optionObject.optionKey.length !== 0 && optionObject.optionValues.length > 0) {
        optionsList.push(optionObject);
        order++;
      }
    });
    // Update this.optionsList with new data
    this.optionsList = optionsList;

    if (this.optionsList.length < 1) {
      this.variantsList = []
    }

    window.dispatchEvent(new CustomEvent("change",{detail: { variantOptionsList: this.optionsList, variantOptionSectionsCount: this.variantItemSectionTargets.length }}))
    // Return the updated this.optionsList
    return this.optionsList;
  }

  // Attach Variant Item Layout
  assignValuesToVariantListItem(nested_form, variantListItems) {
    nested_form.setAttribute('data-list-id', variantListItems[0].listId);
    variantListItems.forEach(object => {
      const { fieldName, value, listId } = object
      const productTitle = document.querySelector("#product_title").value
      let element = nested_form.querySelector(`.variant_${fieldName}`)
      const currentVariant =  this.variantsList.find(variant => variant.listId === listId);
      const currentVariantId = variantListItems.find(variant => variant.fieldName === 'id').value
      if (fieldName.includes("title")) {
        element.textContent = value
        element.href = `/admin/resources/variants/${currentVariantId}/edit`
        this.appendBadgeElement(element, currentVariantId, value)
      } else if (fieldName === "avatar") {
        if (value) {
          $(element).attr('src', value)
        }
      } else if (fieldName.includes("sku") && !currentVariant.sku ) {
        const SKU = this.generateSKU(productTitle, currentVariant)
        element.value = SKU
      } else {
        element.value = value
      }
      element.dataset.listId = listId
      element.dataset.fieldName = fieldName
    })
  }

  appendBadgeElement(parentElement, id, value) {
    // Check if the variant id exists
    if (!id ) {
      // Create and configure a new span element
      const span = this.createSpanElement('text-primary font-medium overflow-auto break-words my-auto variant_title', value);
      // Replace the parent element with the new span
      parentElement.parentNode.replaceChild(span, parentElement);
      // Create a badge for new variant
      const badgeSpan = this.createSpanElement('inline-block whitespace-nowrap rounded-full bg-primary-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] mx-2 leading-none text-primary-700 dark:bg-slate-900 dark:text-primary-500', i18n.t('variants.new'));
      // Append the badge to the new span
      span.appendChild(badgeSpan);
    }
  }

  createSpanElement(className, textContent) {
    const span = document.createElement('span');
    span.className = className;
    span.textContent = textContent;
    return span;
  }

  //this function generate SKU
  generateSKU(productTitle, variant) {
    const productTitleLetters = productTitle.split(" ").map(word => word.charAt(0).toUpperCase()).join("");
    const variantIdentifiers = variant.variantCombinations.map(word =>  word.charAt(0).toUpperCase()).join(""); /// ["Small"."Green", "Cotton"] => SGC
    const variantSKU = `${productTitleLetters}-${variant.listId}-${variantIdentifiers}`;
    return variantSKU;
  }

  addMoreOptionLinkHandler() {
    const addAnother = document.querySelector(`.add-another-option`);
    const isVariantCountBelowLimit = this.variantItemSectionTargets.length < 3;

    this.addOptionTarget.classList.toggle('hidden', !isVariantCountBelowLimit);
    addAnother.classList.toggle('border-b', this.variantItemSectionTargets.length > 0);
  }

  //function call when add new option is clicked
  addNewOptionLayout(event) {
    let variantOptionsLayout = event.target.dataset.variantOptionsLayout;
    let parser = new DOMParser();
    let variantOptionsLayoutToHtml = parser.parseFromString(variantOptionsLayout, 'text/html');
    let variantItemSection = variantOptionsLayoutToHtml.body.querySelector('.variant-item-section');
    if (variantItemSection) {
      this.variantOptionsLayoutTarget.appendChild(variantItemSection);
      //add order attribute for new variant item section
      this.variantOptionsLayoutTarget.querySelectorAll('.variant-item-section').forEach((section, index) => {
        if(!section.dataset.order){
          section.dataset.order = index+1;
        };
      })
      this.addMoreOptionLinkHandler();
      this.openSelect2OnInitialization(variantItemSection);

      // dispatch this function to trigger detectVariantOptionChanges
      if (this.variantItemSectionTargets.length <= 3) {
        const eventDetail = { variantOptionsList: this.optionsList, variantOptionSectionsCount: this.variantItemSectionTargets.length };
        window.dispatchEvent(new CustomEvent("detectVariantOptionChanges", { detail: eventDetail }));
      }
    } else {
      console.error('variant-item-section not found in the parsed HTML.');
    }
  }

  openSelect2OnInitialization(variantOptionsHtml) {
    let id = this.sixDigitRandomID();
    const newSelect = $(variantOptionsHtml).find('select');
    const currentOptionListSelector = this.currentOptionListSelector
    const allSelectedOptionsListSelectors = this.optionsListAllSelectors
    self = this
    newSelect.attr('id', id);

    // Setting the selected option in the new select tag
    newSelect.val(currentOptionListSelector);
    this.inputDefaultPlaceholder(currentOptionListSelector, $(this.variantItemSectionTarget).find(".variant_input")[0])

    // Initialize Select2
    $(`#${id}`).select2({
      minimumResultsForSearch: -1,
    }).on('change', function() {
      self.handleSelect2Change(this, currentOptionListSelector, allSelectedOptionsListSelectors);
    });

    $(`#${id}`).trigger('change');
    $(`#${id}`).select2('open');
  }

  handleSelect2Change(selectElement, currentOptionListSelector, allSelectedOptionsListSelectors) {
    const context = selectElement.closest('.variant-item-section');
    const optionContext = context.querySelector('.optionContext');
    const errorSpan = context.querySelector('.errorSpan');
    const selectedValue = selectElement.value;

    this.inputDefaultPlaceholder(selectedValue, $(context).find(".variant_input")[0]);
    optionContext.textContent = selectedValue; // setting option value

    // Check if the selected option has already been selected
    if (allSelectedOptionsListSelectors.includes(selectedValue) && selectedValue !== currentOptionListSelector) {
      this.displaySelect2Error(selectElement, errorSpan, selectedValue);
    } else {
      this.clearSelect2Error(selectElement, errorSpan);
    }
  }

  displaySelect2Error(selectElement, errorSpan, selectedValue) {
    $(selectElement).siblings('.select2-container')
                    .find('.select2-selection')
                    .css('background-color', '#FEE9E9');
    // Display error message
    const capitalizedValue = selectedValue.charAt(0).toUpperCase() + selectedValue.slice(1);
    $(errorSpan).siblings().removeClass('hidden');
    errorSpan.textContent = `"${capitalizedValue}".`;
  }

  clearSelect2Error(selectElement, errorSpan) {
    $(errorSpan).siblings().addClass('hidden');
    errorSpan.textContent = '';
    $(selectElement).siblings('.select2-container')
                    .find('.select2-selection')
                    .css('background-color', '#F8F8F8');
  }

  inputDefaultPlaceholder (newOptionValue, Input) {
    switch (newOptionValue) {
      case "color":
        Input.placeholder = i18n.t('variants.black');
        break;
      case "material":
        Input.placeholder = i18n.t('variants.rubber');
        break;
      case "style":
        Input.placeholder = i18n.t('variants.classic');
        break;
      case "size":
        Input.placeholder = i18n.t('variants.medium');
        break;
      default:
        Input.placeholder = i18n.t('variants.add_another_value');
    }
  }

  variantSectionRemoveHandler(event) {
    const productId = document.querySelector('div[data-record-id]').getAttribute('data-record-id');
    setTimeout(() => {
      event.target.closest('.variant-item-section').remove();
      this.addMoreOptionLinkHandler()
      this.variantListManagement()

      if (this.variantsList.length === 0) {
        this.selectAllVariantsTarget.checked = false;
        Controller.deleteAllVariantOption = true;
        if (Controller.deleteAllVariantOption) {
          $.ajax({
            url: `/admin/delete_variants/${productId}`,
            type: 'DELETE',
            success: function(response) {
              if (response.status == 200) {
                Controller.deleteAllVariantOption = false;
              }
            },
            error: function(xhr, status, error) {console.error("Error Variants Options:", error)}
          });
        }
      }
    }, 1000)
  }

  deleteAnOptionFieldHandler(event) {
    setTimeout(() => {
      let optionValue = event.target.previousElementSibling.value;
      let optionKey = event.target.previousElementSibling.dataset.optionKey;
      let id = event.target.previousElementSibling.dataset.sectionId;
      let result = `${optionKey}-${optionValue}-${id}`;
      let matchedElements = $(`.nested-form-fields.${result}`)
      Array.from(matchedElements).forEach(matchedEl => {
        matchedEl.classList.add('hidden');
        var destroyInput = matchedEl.closest('.fields')
        .querySelector('input[name*="[_destroy]"]');
        destroyInput.value =  1;
      })
    }, 500);
  }

  // A unique random ID for each Variant Item Layout
  sixDigitRandomID() {
    return Math.floor(100000 + Math.random() * 900000)
  }

  modifyVariantObject(event) {
    const dataListId = event.target.dataset.listId;
    if (this.variantsList.length > 0) {
      const editableVariantModal = document.querySelector('.editable-variant-modal-' + dataListId);
      const toggleVariantModal = document.querySelector('.toggle-variant-modal');
      const variantFieldSection = document.querySelector(`.variant-field-section`);
      const currentVariantList = document.querySelector(`.nested-form-fields[data-list-id="${dataListId}"]`);

      const variantListData = {
        variantCombinations: [],
        available: null,
        barcode: null,
        listId: null,
        price: null,
        sku: null,
        options: null,
        id: null,
        archived: null,
        newArchived: null,
        avatar_url: null
    };

    // Retrieving values from currentVariantList
    if (currentVariantList) {
        try {
            variantListData.available = currentVariantList.querySelector('.variant_available').value;
            variantListData.barcode = currentVariantList.querySelector('.variant_barcode').value;
            variantListData.listId = parseInt(currentVariantList.getAttribute('data-list-id'));
            variantListData.price = currentVariantList.querySelector('.variant_price').value;
            variantListData.sku = currentVariantList.querySelector('.variant_sku').value;
            variantListData.options = currentVariantList.querySelector('.variant_options').value;
            variantListData.id = parseInt(currentVariantList.querySelector('.variant_id').value);
            variantListData.archived = currentVariantList.querySelector('.variant_archived').value === 'true';
            variantListData.avatar_url = currentVariantList.querySelector('.variant_avatar').getAttribute('src');
        } catch (error) {
            console.error("Error retrieving variant data:", error);
        }
    }

    // Parsing options
    if (variantListData.options) {
        try {
            const options = JSON.parse(variantListData.options);
            options.forEach(option => {
                variantListData.variantCombinations.push(option.optionValues[0]);
            });
        } catch (error) {
            console.error("Error parsing options:", error);
        }
    }
      let saveVariantModal = document.querySelector('.save-variant-modal');
      if (variantFieldSection.classList.contains('hidden')) {
        variantFieldSection.classList.remove('hidden')
        this.rememberTarget.value = '1';
        this.rememberTarget.checked = true;
      }

      // remove modifyVariantObject using save-variant-modal class if it exists
      this.variantsList.forEach((object) => {
        const { listId } = object;
        if (saveVariantModal.classList.contains('save-variant-modal-' + listId)) {
          saveVariantModal.classList.remove('save-variant-modal-' + listId);
        }
      })

      saveVariantModal.classList.add('save-variant-modal-' + dataListId);
      saveVariantModal.setAttribute('data-list-id', dataListId);
      Object.keys(variantListData).forEach((key) => {
        if (["id", "archived", "newArchived"].some(str => key.includes(str))) {
          return;
        }

        if (!key.includes("listId") && !key.includes("remember")) {
          let element = editableVariantModal.querySelector(`.modify-variant-${key}`)
          let value = variantListData[key];

          if (key.includes("variantCombinations")) {
            element.textContent = value.join(' / ')
          } else if (key !== "avatar_url" && !key.includes('options')) {
            element.value = value;
          }
        }
      });

      toggleVariantModal.click();
    }
  }

  saveVariantObject(event) {
    const dataListId = event.target.dataset.listId;
    const currentEditableVariant = document.querySelector(`.editable-variant-modal-${dataListId}`);
    const inputElements = currentEditableVariant.querySelectorAll('input');
    const variantsListSection = document.querySelector("[data-variant-list-section-target=variantsListSection]");
    const currentVariantItem = variantsListSection.querySelector(`[data-list-id="${dataListId}"]`);
    const currentVariantList = document.querySelector(`.nested-form-fields[data-list-id="${dataListId}"]`);
    const inputValues = {};

    inputElements.forEach((input) => {
      inputValues[input.name] = input.value;
    });

    // Update the currentVariantItem with inputValues
    for (const key in inputValues) {
      if (inputValues.hasOwnProperty(key)) {
        currentVariantItem[key] = inputValues[key];
      }
    }

    // Update the input values in the currentVariantList
    const inputElementsInList = currentVariantList.querySelectorAll('input');
    inputElementsInList.forEach((input) => {
      const name = input.getAttribute('data-field-name');
      if (inputValues.hasOwnProperty(name)) {
        input.value = inputValues[name];
      }
    })


    const isChecked = this.rememberTarget.checked

    if (!isChecked) {
      if (currentVariantItem) {
        this.checkIsValueAssignedSomewhere(currentVariantItem, currentVariantList, dataListId)
      }
    }
    event.target.previousElementSibling.click();
  }

  handleRememberChange(){
    const isChecked = this.rememberTarget.checked;
    let rememberValue = this.rememberTarget.value
    const variantFieldSection = document.querySelector(`.variant-field-section`);

    if (isChecked) {
      rememberValue = '1'
      variantFieldSection.classList.remove('hidden')
    }
    else {
      rememberValue = '0'
      variantFieldSection.classList.add('hidden')
    }
  }

  removeVariantItemFromList(event){
    const dataListId = event.target.dataset.listId;
    const currentVariantItem = this.variantsList.find(variantList => variantList.listId == dataListId);
    const currentVariantFieldItem = event.target.closest('.nested-form-fields')

    if (currentVariantItem) {
      this.checkIsValueAssignedSomewhere(currentVariantItem, currentVariantFieldItem, dataListId)
      this.handleVariantListChanges();
    }
  }

  handleVariantListChanges() {
    // Dispatch a custom event to handle changes in the variants list in detect_product_changer
    window.dispatchEvent(new CustomEvent("detectChangesInVariantList", { detail: { variantsList: this.getNotArchivedVariants(this.variantsList) } }));
    const optionsListCopy = [...this.optionsList];
    const updatedOptionsList = this.getUpdatedOptionsList(this.variantsList, optionsListCopy);
    this.setVariantItemSectionValues(updatedOptionsList);

    const visibleNestedVariantFormsCount = $('.nested-variant-forms:hidden').length - 1;
    const notArchivedVariantsListLength = this.variantsList.filter(variant => {return !variant.newArchived}).length

    if (visibleNestedVariantFormsCount === notArchivedVariantsListLength) {
      const filter_options_list_html = this.element.querySelector(".filter-options");
      filter_options_list_html.classList.add("hidden");
      this.variantsListSectionTarget.classList.add("hidden");
    }
  }

  checkIsValueAssignedSomewhere(currentVariantItem, currentVariantFieldItem, dataListId) {
    const values = currentVariantItem.variantCombinations;

    // Check if all values in currentVariantItem's variantCombinations match any values in other items
    const isValueAssignedSomewhere = values.some(value =>
      this.variantsList.some(variant =>
        variant.listId != dataListId &&
        variant.variantCombinations.includes(value)
      )
    );
    if (isValueAssignedSomewhere || this.storeCartesianCombinationsList(this.optionsList).length >= 1) {
      this.manageCurrentVariantFieldItem(true, currentVariantFieldItem);
      // This function is used to show addMoreOption button
      //   this.addMoreOptionLinkHandler();
    }
  }

  // Undo styling of object fields
  undoVariantItemFromList(event) {
    const currentVariantFieldItem = event.target.closest('.nested-form-fields')
    this.manageCurrentVariantFieldItem(false, currentVariantFieldItem)
    const optionsListCopy = [...this.optionsList];
    const updatedOptionsList = this.getUpdatedOptionsList(this.variantsList, optionsListCopy);
    this.setVariantItemSectionValues(updatedOptionsList)
  }

  // Scroll back to list section
  closeModalAndReturnToOriginalPosition(){
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth"
      });
    }, 100);
  }

  getUpdatedOptionsList(variantsData, optionsList) {
    const allVariantCombinations = variantsData
        .filter(variant => !variant.newArchived)
        .flatMap(variant => variant.variantCombinations);

    const updatedOptionsList = optionsList.map(option => {
        return {
            ...option,
            optionValues: option.optionValues.filter(value => allVariantCombinations.includes(value))
        };
    });

    return updatedOptionsList;
  }

  // Toggle styling of object fields
  manageCurrentVariantFieldItem(canRemove, currentVariantFieldItem) {
    const listId = currentVariantFieldItem.dataset.listId;
    let selectedVariant = this.variantsList.find(variant => variant.listId == listId);
    const currentEditObject = currentVariantFieldItem.querySelector('.variant_edit_object');
    const currentEditTitle = currentVariantFieldItem.querySelector('.variant_title');
    const archivedInput = currentVariantFieldItem
                            .closest('.fields')
                            .querySelector('input[name*="[archived]"]');

    selectedVariant.newArchived = canRemove ? true : false;
    currentEditTitle.style.textDecoration = canRemove ? 'line-through' : 'none';

    const undoSection = currentEditObject.closest('.actions-section').querySelector('.variant_undo_section');
    const deleteSection = currentEditObject.closest('.actions-section').querySelector('.variant_delete_section');

    undoSection.classList.toggle('hidden', !canRemove);
    deleteSection.classList.toggle('hidden', canRemove);
    $(currentVariantFieldItem).closest('.nested-form-fields').toggleClass('hidden', canRemove)

    currentVariantFieldItem.querySelectorAll('.undo-input').forEach((selector) => {
      selector.style.backgroundColor = canRemove ? '#E5E5E5' : '';
      selector.style.pointerEvents = canRemove ? 'none' : 'auto';
      selector.readonly = canRemove;
    });

    const selectedVariantCheckbox = currentVariantFieldItem.querySelector(`[data-variant-filters-target="selectedVariantListItem"]`);
    selectedVariantCheckbox.style.backgroundColor =  canRemove ? '#CED4DA' : '';
    selectedVariantCheckbox.disabled = canRemove;

    currentVariantFieldItem.querySelectorAll('.undo-bg').forEach((selector) => {
      selector.style.backgroundColor = canRemove ? '#F1F2F3' : '';
    });
    currentVariantFieldItem.style.backgroundColor = canRemove ? '#F1F2F3' : '';

    archivedInput.value = canRemove ? '1' : '0';

    if(this.missingOptionValues.length > 0){
      currentVariantFieldItem.classList.toggle('hidden', canRemove);
    }
  }

  //edit bulk prices for selected variants
  modifyBulkVariantsPrice() {
    if (this.selectedVariantItems.length > 0) {
      const toggleVariantModal = document.querySelector('.toggle-variant-modal');
      const variantFieldSection = document.querySelector(`.variant-field-section`);
      let saveVariantModal = document.querySelector('.save-variant-modal');

      if (variantFieldSection.classList.contains('hidden')) {
        variantFieldSection.classList.remove('hidden');
      }

      const modalVariantRememberCheck = document.querySelector('.variant-remember-section');
      const modalVariantFieldSection = document.querySelector('.variant-field-section');

      // Remove the variant-remember-check
      modalVariantRememberCheck.remove();
      // Clear the inner HTML of variant-field-section
      modalVariantFieldSection.innerHTML = "";

      // Iterate through selectedVariantItems and generate input fields and variantCombination lists
      this.selectedVariantItems.forEach((variant, index) => {
        // Create a new input field for the price
        const variantWrapper = document.createElement('div')
        variantWrapper.classList.add('flex', 'justify-between', 'px-2', 'py-2', 'border-b-2', 'border-gray-300')
        variantWrapper.setAttribute('data-list-id', variant.listId)
        const priceInput = document.createElement('input');
        priceInput.type = 'text';
        priceInput.value = variant.price; // Replace this with the actual property you want to display
        priceInput.name = `variant_price_${variant.listId}`; // Replace this with a meaningful name
        // Add any additional attributes or classes as needed

        // Create a new span for variantCombinations
        const variantCombinationSpan = document.createElement('span');
        variantCombinationSpan.textContent = variant.variantCombinations.join(' / ');
        // Append the input field and span to the variant-field-section
        variantWrapper.appendChild(priceInput)
        variantWrapper.appendChild(variantCombinationSpan)
        modalVariantFieldSection.appendChild(variantWrapper);
      });

      // Click the toggleVariantModal to open the modal
      toggleVariantModal.click();
    }
  }
}
