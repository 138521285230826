export function createElement(tag, options = {}) {
	const element = document.createElement(tag)

	if (options.classes) {
		element.classList.add(...options.classes)
	}
	if (options.attributes) {
		for (const [key, value] of Object.entries(options.attributes)) {
			element.setAttribute(key, value)
		}
	}
	if (options.textContent) {
		element.textContent = options.textContent
	}
	return element;
}

export function updateAttributes (element, attributes, action = 'set') {
	if (action === 'set') {
		for ( const [key, value] of Object.entries(attributes) ) {
			element.setAttribute(key, value)
		}
	} else if (action === 'remove') {
		for ( const [key, value] of Object.entries(attributes) ) {
			element.removeAttribute(key, value)
		}
	} else {
		console.error(`Invalid action: ${action}. Use 'set' or 'remove'.`);
	}
}
