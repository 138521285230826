import { Controller } from "@hotwired/stimulus";
import { i18n } from "../components/locales_handler"
import { createElement } from "../components/utils";

export default class extends Controller {
  static targets = [
    "variantItemSection", "optionValue", "variantFilterDropdown", "filtersUl",
    "filterOptionCheckbox", "selectedVariantListItem", "selectAllVariants",
    "nestedFormFields", "variantFilterMenu", "variantsListBody", "bulkActionsMenu"
  ]

  connect() {
    this.variantsList = [];
    this.variantOptionsFilterArray = [];

    document.addEventListener("initializeVariantsList", this.handleInitializeVariantsList.bind(this));
    document.addEventListener("generateVariantFilters", this.generateVariantFilters.bind(this));
    document.addEventListener("assignUniqueClassesToVariants", this.assignUniqueClassesToVariants.bind(this));
    document.addEventListener("generateFilterOptionsUI", this.generateFilterOptionsUI.bind(this));
    document.addEventListener("resetVariantSelections", this.resetVariantSelections.bind(this));

    this.resetVariantSelections();
  }

  resetVariantSelections () {
    this.selectedVariantListItemTargets.forEach((checkbox) =>  checkbox.checked = false);
    this.filterOptionCheckboxTargets.forEach((checkbox) =>  checkbox.checked = false);
    this.selectAllVariantsTarget.checked = false;
    this.selectedVariantItems = [];
    this.updateVariantSelection();
  }

  handleInitializeVariantsList(event) {
    this.variantsList = event.detail.variantsList;
  }

  generateVariantFilters() {
    this.variantOptionsFilterArray = [];

    this.variantItemSectionTargets.forEach(target => {
      const sectionId = target.dataset.id;
      const filterObject = {
        sectionId: sectionId,
        optionKey: '',
        optionValues: []
      };

      this.optionValueTargets
        .filter(field => field.dataset.sectionId === sectionId && field.value.trim().length !== 0)
        .forEach(field => {
          filterObject.optionKey = field.dataset.optionKey;

          const existingKeyIndex = this.variantOptionsFilterArray.findIndex(filterItem => filterItem.optionKey === field.dataset.optionKey);
          if (existingKeyIndex !== -1) {
            // If the optionKey already exists, update the existing entry's optionValues
            this.variantOptionsFilterArray[existingKeyIndex].optionValues = this.optionValueTargets
              .filter(valueField => valueField.dataset.optionKey === field.dataset.optionKey && valueField.value !== '')
              .map(valueField => valueField.value);
          } else {
            filterObject.optionValues.push(field.value);
            this.variantOptionsFilterArray.push({ ...filterObject });
          }
        });
    });
  }
  /* Adds a unique class to a variant list item for efficient filtering based on selected options
    *and in necessarily in each variant combination format
    *e.g variantCombination:['xl','red', 'rubber'], classes generated will be
    *size-xl-123, color-red-345, material-rubber-678
  */
  assignUniqueClassesToVariants(event) {
    const nested_form = event.detail.nested_form
    const variant = event.detail.variant
    const filterOptionsArray = this.variantOptionsFilterArray
    //sets to keep record of already added key or combination
    const existingKeys = new Set();
    const existingCombinations = new Set();

    const sectionAndOptionKeys = filterOptionsArray.map(filter => ({ sectionId: filter.sectionId, optionKey: filter.optionKey }));

    if (sectionAndOptionKeys.length === 0) return;
    const latestOptionKey = sectionAndOptionKeys[sectionAndOptionKeys.length - 1];
    const matchingFilter = filterOptionsArray.find((filter) => filter.optionKey === latestOptionKey.optionKey);
    if (matchingFilter) {
      // Check if the key has already been added to a combination
      if (existingKeys.has(matchingFilter.optionKey.toLowerCase())) {
        return; // Skip this variant if the key is already used in another combination
      }
      variant.variantCombinations.forEach(combination => {
        sectionAndOptionKeys.forEach(option => {
          const prefix = option.optionKey.toLowerCase();
          const generatedCombinationForClass = this.generateVariantCombinationClass(combination)
          const classToAdd = `${prefix}-${generatedCombinationForClass}-${option.sectionId}`;

          // Check if the key has already been added to a combination OR
          // combination already exists on the nested_form
          if (existingKeys.has(prefix) || existingCombinations.has(generatedCombinationForClass)) {
              return; // Skip this variant if the key is already used in another combination
          }
          // Add the key to existingKey to ensure it's not used again
          existingKeys.add(prefix);
          // Add the class to the set to ensure uniqueness
          existingCombinations.add(generatedCombinationForClass);
          // Add the specific class to nested_form
          nested_form.classList.add(classToAdd);
        });
      })
    }
  }

  /* function to join variant combination string
    e.g generateVariantCombinationClass(xlspacesm)
    result will be xl-sm ,
  */
  generateVariantCombinationClass(combination) {
    return combination.trim().split(/\s+/).join('-');
  }

  generateFilterOptionsUI () {
    const filter_options_list_html = this.filtersUlTarget;

    /* create filter div and populate data
        All/None do not have any optionValues. so, prevent creating filters for it
    */
    if (this.variantOptionsFilterArray.length > 0) {
      // filter_options_list_html.innerHTML = "";
      this.variantFilterDropdownTargets.forEach((li) => {
        li.remove();
      })
      // Check if filters ul element is present
      if (filter_options_list_html) {
        // create list item for each option value and append to ul
        this.variantOptionsFilterArray.forEach(filterItem => {
          const listItem = this.createVariantFilterDropdowns(filterItem);
          filter_options_list_html.appendChild(listItem);
        });
      }
    }
  }

  // this function creates each filter category and its respective filters in the DOM
  createVariantFilterDropdowns(filterItem) {
    const li = createElement('li', {});
    const button = this.createFilterButton(filterItem.optionKey);

    li.setAttribute('data-variant-filters-target', 'variantFilterDropdown')

    if (filterItem.optionValues.length > 0) {
      const span = this.createDropdownIndicator();
      button.appendChild(span)

      const variantFilterMenu = this.createVariantFilterMenu(filterItem.optionKey);
      const optionValuesDiv = this.createOptionValuesDiv();

      const existingPrefixes = new Set();
      const prefix = filterItem.optionKey.toLowerCase();

      if (!existingPrefixes.has(prefix)) {
        // Add the prefix to the set to ensure uniqueness
        existingPrefixes.add(prefix);

        filterItem.optionValues.forEach(optionValue => {
          const filterCheckboxContainer = this.createFilterCheckboxContainer(prefix, optionValue, filterItem.optionKey ,filterItem.sectionId);
          const filterCheckboxInput = this.createFilterCheckboxInput(optionValue);
          const filterCheckboxLabel = this.createFilterCheckboxLabel(optionValue, filterCheckboxInput);

          filterCheckboxContainer.appendChild(filterCheckboxLabel);
          optionValuesDiv.appendChild(filterCheckboxContainer);
        });

        variantFilterMenu.appendChild(optionValuesDiv);
        li.appendChild(variantFilterMenu);
      }
    }

    li.appendChild(button);
    return li;
  }

  createFilterButton(optionKey) {
    return createElement('button', {
      classes: ['text-primary', 'hover:text-blue-800', 'flex', 'gap-1', 'justify-content-start', 'items-center', 'group', 'capitalize', 'hover:underline', "align-baseline"],
      attributes: {
        type: 'button',
        id: `${optionKey}-options`,
        'aria-expanded': 'false',
        'data-action': 'click->variant-filters#toggleFilterDropdownMenuHandler',
        'data-variant-filters-target': 'variantFilterButton',
        'data-option-key': optionKey
      },
      textContent: optionKey
    });
  }

  createDropdownIndicator() {
    const span = createElement('span', { classes: ['mt-1'] });
    const svg = this.createDownArrowSVG();
    span.appendChild(svg);
    return span;
  }

  // Call the function to create the arrow down SVG for filters
  createDownArrowSVG() {
    const svgString = `
      <svg fill="#38128A" height="10px" width="10px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve" stroke="#38128A">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"></path>
        </g>
      </svg>
    `;

    const svg = new DOMParser().parseFromString(svgString, "image/svg+xml").documentElement;
    return svg;
  }

  createVariantFilterMenu(optionKey) {
    return createElement('div', {
      classes: ['hidden', 'absolute', 'z-50', 'w-50', 'top-10', 'bg-white', 'shadow-lg', 'origin-top-right', 'rounded-xl', 'ring-1', 'ring-black', 'ring-opacity-5', 'select-none'],
      attributes: {
        'data-variant-filters-target': 'variantFilterMenu',
        'data-option-key': optionKey
      }
    });
  }

  createOptionValuesDiv() {
    return createElement('div', {
      classes: ['py-1'],
      attributes: { role: 'menu', 'aria-orientation': 'vertical' }
    });
  }

  createFilterCheckboxContainer(prefix, optionValue, optionKey ,sectionId) {
    const generatedCombination = this.generateVariantCombinationClass(optionValue);

    return createElement('div', {
      classes: ['mb-[0.125rem]', 'block', 'min-h-[1.5rem]', 'px-[1rem]', 'py-[0.7rem]', `${prefix}-${generatedCombination}-${sectionId}`, 'hover:cursor-pointer'],
      attributes: {
        'data-section-id': sectionId,
        'data-option-key': optionKey
      }
    });
  }

  createFilterCheckboxInput(optionValue) {
    return createElement('input', {
      classes: ['mr-2', 'rounded', 'focus:outline:none', 'bg-black', 'border-gray-300', 'select-none'],
      attributes: {
        type: 'checkbox',
        value: optionValue,
        id: `checkbox_${optionValue}`,
        'data-variant-filters-target': 'filterOptionCheckbox',
        'data-action': 'click->variant-filters#filterOptionCheckboxHandler'
       }
    });
  }

  createFilterCheckboxLabel(optionValue, input) {
    const label = createElement('label', {
      classes: ['inline-block', 'pl-[0.15rem]', 'hover:cursor-pointer', 'select-none'],
      attributes: { htmlFor: `checkbox_${optionValue}` },
      textContent: optionValue
    });
    label.prepend(input);
    return label;
  }

  //filter dropdown toggle visibility action
  toggleFilterDropdownMenuHandler(event) {
    event.stopImmediatePropagation();
    const buttonText = event.currentTarget.innerText;
    const isSelectAllVariants = buttonText === 'All';
    const isSelectNone = buttonText === 'None';

    isSelectAllVariants ? this.updateVariantSelection(true) : isSelectNone ? this.updateVariantSelection(false) : null;
    // Find the variantFilterMenu div associated with the clicked element
    const toggleableDiv = event.currentTarget.closest("li").querySelector("[data-variant-filters-target='variantFilterMenu']");
    const shouldShowToggleableDiv = event.currentTarget.getAttribute('aria-expanded') === 'false';

    this.hideAllToggleableDivs();
    if(toggleableDiv) {
      toggleableDiv.classList.toggle("hidden", !shouldShowToggleableDiv);
      event.currentTarget.setAttribute('aria-expanded', shouldShowToggleableDiv ? 'true' : 'false');
    }
  }

  //update variants selection and manage selected variants state
  updateVariantSelection(isChecked) {
    this.filterOptionCheckboxTargets.map(filterOptions => filterOptions.checked = isChecked);
    this.selectAllVariantsTarget.checked = isChecked;
    this.selectedVariantListItemTargets.map(x => {
      if (!x.disabled) {
        x.checked = isChecked;
      }
    });
    this.updateSelectedFilters();
  }

  // Hide all variantFilterMenu divs on the page
  hideAllToggleableDivs() {
    this.variantFilterMenuTargets.forEach(filterMenu => {
      filterMenu.classList.add("hidden");
      // also update the aria-expanded attribute for each button to 'false'
      const variantFilterButton = filterMenu.nextElementSibling;
      variantFilterButton.setAttribute('aria-expanded', 'false');
    });
  }

  //re-usable function to update selected filters array
  updateSelectedFilters() {
    this.selectedFilterOptions = [];
    this.filterOptionCheckboxTargets.forEach(filterCheckbox => {

        // Clear the selectedFilterOptions array
        if (filterCheckbox.checked) {
            const checkboxValue = filterCheckbox.value.trim();
            this.selectedFilterOptions.push(checkboxValue);
        }
    });
    this.updateSelectedVariants();
  }

  //re-usable function to update state of selected variant items
  updateSelectedVariants() {
    this.selectedVariantItems = [];
    const nested_forms_fields = this.nestedFormFieldsTargets.filter(nestedForm => nestedForm.hasAttribute('data-list-id'));
    nested_forms_fields.forEach(formField => {
      const isItemChecked = formField.querySelector('input[type=checkbox]').checked;

      if(isItemChecked){
        const listId = parseInt(formField.dataset.listId);
        const selectedVariant = this.variantsList.find(variant => variant.listId === listId);
        this.selectedVariantItems.push({...selectedVariant});
      }
    })
		document.dispatchEvent(new CustomEvent('updateSelectedVariantItems', {detail: {selectedVariantItems: this.selectedVariantItems}}))
    this.toggleBulkActionsMenu();
  }

  toggleBulkActionsMenu() {
    const bulkActionsMenuParent = $('.bulk-actions-menu').parent();
    bulkActionsMenuParent.toggleClass('hidden', this.selectedVariantItems.length === 0);
  }

  //function that toggle each variant checkbox w.r.t classes on both filter and variant
  filterOptionCheckboxHandler(event) {
    event.stopImmediatePropagation();
    const target = event.target;
    const sectionId = target.closest('div').getAttribute('data-section-id');;
    const filterValue = target.value.trim();
    const nestedFormsFields = this.nestedFormFieldsTargets.filter(nestedForm => nestedForm.hasAttribute('data-list-id'));
    const selectedFilterOptionKey = target.closest('div').dataset.optionKey;

    const isMatchedSection = this.variantOptionsFilterArray.findIndex(option => option.sectionId == sectionId);

    nestedFormsFields.forEach(listItem => {
      const classes = listItem.classList;
      const listItemValue = listItem.querySelector('.variant_title').innerText;
      const variantListItemCheckbox = listItem.querySelector('input[type="checkbox"]');

      const isNotHidden = !listItem.closest(".nested-variant-forms").classList.contains("hidden");
      const hasMatchingClass = classes.contains(`${selectedFilterOptionKey}-${filterValue}-${sectionId}`);
      const valueIncludesFilter = listItemValue.includes(filterValue);
      const isSectionMatched = isMatchedSection !== -1;

      if (isNotHidden && hasMatchingClass && valueIncludesFilter && isSectionMatched && !variantListItemCheckbox.disabled) {
        variantListItemCheckbox.checked = target.checked;
      }
    });

    const selectedVariantListItems = nestedFormsFields.filter(nestedForm =>
      nestedForm.querySelector('[data-variant-filters-target="selectedVariantListItem"]').checked
    );

    if(!selectedVariantListItems.length > 0) {
      this.filterOptionCheckboxTargets.forEach(filterOptionCheckbox =>
        filterOptionCheckbox.checked =  false
      );
    }
    selectedVariantListItems.forEach((selectedVariantItem) => {
      const selectedVariantItem_classes = selectedVariantItem.classList;
      const { matchedVariantSpecialClasses } = this.findMatchedClasses(selectedVariantItem_classes);
      matchedVariantSpecialClasses.forEach(className => {
        const relevantFilterOptionValues = this.filterOptionCheckboxTargets.find(filterOptionCheckbox =>
          filterOptionCheckbox.closest('div').classList.contains(className)
        );
        relevantFilterOptionValues.checked = true;
      });
    })

    const allChecked = this.selectedVariantListItemTargets.slice(1).every(checkbox => checkbox.checked);

    if(allChecked) {
      this.selectAllVariantsTarget.checked = true;
      this.filterOptionCheckboxTargets.forEach(checkbox => checkbox.checked = true)
    } else {
      this.selectAllVariantsTarget.checked = false;
    }
    this.updateSelectedFilters();
  }

   // Function to check if all checkboxes for specific classes are checked
  allCorrespondingVariantsChecked(container, classesToFind) {
    return classesToFind.map(className => {
      const variantsWithMatchedClasses = this.getElementsWithClasses(container, [className]);
      return this.areAllCheckboxesChecked(variantsWithMatchedClasses);
    });
  }

  // Select All checkbox handler
  handleAllVariantSelection() {
    const isChecked = this.selectAllVariantsTarget.checked;
    this.updateVariantSelection(isChecked)
  }

  findMatchedClasses(variantItemSelectedClasses) {
    // Function to extract classes containing "size," "color," or "material"
    const extractSpecialClasses = (classes) => {
      return Object.values(classes).filter(value => value.includes('size') || value.includes('color') || value.includes('material') || value.includes('style') );
    };

    // Extract classes containing "size," "color," or "material" for each class list
    const variantSpecialClasses = extractSpecialClasses(variantItemSelectedClasses);

    return {
        matchedVariantSpecialClasses: variantSpecialClasses
    };
  }

  // Manage selected variants and filter list checkbox clicks
  selectedVariantListItemHandler(event) {
    const variantItemClasses = event.target.closest(".nested-form-fields").classList;
    // Get classes that start with size, color, material, or style
    const { matchedVariantSpecialClasses } = this.findMatchedClasses(variantItemClasses);
    const variantsListContainer = this.variantsListBodyTarget;
    const areAllSpecialClassesSelected = this.allCorrespondingVariantsChecked(variantsListContainer, matchedVariantSpecialClasses);

    // Update the filter checkboxes based on the selected variant item
    areAllSpecialClassesSelected.forEach((isClassSelected, index) => {
      const specialClassName = matchedVariantSpecialClasses[index];
      // Find the corresponding checkbox in filterOptionCheckboxTargets with the matching class
      const correspondingCheckbox = Array.from(this.filterOptionCheckboxTargets).find(filterCheckbox => {
          return filterCheckbox.closest('div').classList.contains(specialClassName);
      });

      correspondingCheckbox.checked = isClassSelected;
    });

    this.updateSelectedVariants();

    // Toggle the "Select All" checkbox based on the state of individual variant items
    const areAllVariantsChecked = this.selectedVariantListItemTargets.map(checkbox => checkbox.checked).includes(false);
    this.selectAllVariantsTarget.checked = !areAllVariantsChecked;
  }

  // Function to get elements with specified classes within the container element
  getElementsWithClasses(container, classesArray) {
    const selector = classesArray.map(className => `.${className}`).join(', ');
    return container.querySelectorAll(selector);
  }

  areAllCheckboxesChecked(elements) {
    // Convert NodeList to Array for easier manipulation
    const elementArray = Array.from(elements);

    // Check if all checkboxes inside the elements are checked
    return elementArray.some(element => {
      return element.querySelector('[data-variant-filters-target="selectedVariantListItem"]').checked
    })
  }
}
